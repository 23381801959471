<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-6 col-sm-7 col-lg-8">
            <h3>
              การขาย
            </h3>
          </div>
          <div class="col-6 col-sm-5 col-lg-4 text-right">
            <div>
              <b-button
                variant="danger"
                class="btn-icon rounded-circle shopping"
                style="box-shadow: 3px 3px 4px 4px #ccc;"
                @click="setPageModal(0), setdataOrder(), openModalCheck()"
              >
                <div class="d-flex seticonwithp">
                  <feather-icon
                    class="setsizeicon"
                    icon="ShoppingCartIcon"
                  />
                  <p style="margin-top: -5px;    margin-left: -8px;">
                    +{{ sumItemShopping }}
                  </p>
                </div>
              </b-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 mb-1">
            <b-form-group style="    margin-bottom: 0rem;">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                  style="margin-left: 1rem;"
                  :style="
                    sizepagee === 'xs' ? 'width: 100%;' : 'max-width: 230px;'
                  "
                />
              </div>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6">
            <div class="d-flex justify-content-end">
              <DateTimePicker
                id="start"
                v-model="DateOrder"
                :style="
                  sizepagee === 'xs' ? 'width: 100%;' : 'max-width: 230px;'
                "
              />
              <!-- <DatePicker
                :style="
                  sizepagee === 'xs' ? 'width: 100%;' : 'max-width: 230px;'
                "
                :date="DateOrder"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              /> -->
            </div>
          </div>
        </div>
        <div
          v-if="rows === 0"
          class="col-md-12 col-sm-6 col-xl-12 text-center"
        >
          <feather-icon
            class="empty-cart"
            icon="ShoppingCartIcon"
          />
          <h4 class="empty-font">
            ไม่พบข้อมูลสินค้า
          </h4>
        </div>
        <div
          v-if="rows > 0"
          class="row mt-2"
        >
          <div
            v-for="(data, index) of items.slice(
              (currentPage - 1) * perPage,
              (currentPage - 1) * perPage + perPage
            )"
            :key="index"
            class="col-12 col-sm-6 col-xl-4"
          >
            <div class="card carditem">
              <div class="card-body">
                <div class="row">
                  <div class="col-5 text-center">
                    <b-img
                      thumbnail
                      fluid
                      :src="baseURL + data.product.pic"
                      alt="Image 1"
                      style="width:100%;max-width: 80px;"
                      @error="setAltImg"
                    />
                    <p class="mt-1">
                      {{ data.product.code }}
                    </p>
                  </div>
                  <div class="col-7">
                    <div
                      class="row"
                      style="margin-bottom: -8px;"
                    >
                      <div class="col-6 nonepading">
                        <p class="text-truncate">
                          ชื่อสินค้า
                        </p>
                      </div>
                      <div class="col-6 nonepading">
                        <p class="text-truncate">
                          {{ data.product.name }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row"
                      style="margin-bottom: 7px;"
                    >
                      <div class="col-6 nonepading align-self-center">
                        <p>
                          คลัง
                        </p>
                      </div>
                      <div class="col-6 nonepading">
                        <b-form-select
                          v-model="setInputs[data.index].inputs.warehouse"
                          :options="setInputs[data.index].warehouse"
                          @change="warehouseChange(data.index)"
                        />
                      </div>
                    </div>
                    <div
                      class="row"
                      style="margin-bottom: -9px;"
                    >
                      <div class="col-6 nonepading">
                        <p class="text-truncate">
                          คงเหลือ
                        </p>
                      </div>
                      <div class="col-6 nonepading">
                        <div class="d-flex">
                          <p class="fSRP mr-1">
                            {{ data.amount | toAmount }}
                          </p>
                          <p>
                            ชิ้น
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      style="margin-bottom: -5px;"
                    >
                      <div class="col-3 col-sm-4 nonepading">
                        <p class="text-truncate">
                          ราคา
                        </p>
                      </div>
                      <div class="col-9 col-sm-8 nonepading text-center">
                        <div v-if="data.promotion.length > 0">
                          <div class="d-flex justify-content-end">
                            <p class="fSRP font-weight-bolder">
                              {{
                                [
                                  {
                                    price: data.product.price.$numberDecimal,
                                    percen:
                                      data.promotion[0].value.$numberDecimal
                                  }
                                ] | toPercen
                              }}
                              <b-badge
                                style="margin-left:3px"
                                variant="danger"
                              >
                                {{
                                  `${data.promotion[0].value.$numberDecimal} %`
                                }}
                              </b-badge>
                            </p>
                          </div>
                          <p
                            class="fSRP"
                            style="margin-top: -10px;"
                          >
                            <s>
                              {{
                                data.product.price.$numberDecimal | toCurrency
                              }}</s>
                          </p>
                        </div>
                        <div v-else>
                          <p class="fSRP mr-1">
                            {{ data.product.price.$numberDecimal | toCurrency }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <b-input-group class="setSpinbutton">
                        <b-input-group-prepend>
                          <b-button
                            variant="outline-secondary"
                            class="py-0"
                            size="sm"
                            style="width: 35px;
  border: 1px solid #d8d6de !important;
      color: white;
    background-color: #EA5354;"
                            @click="valueAmountChange(1, data.index)"
                          >
                            <b-icon
                              icon="dash"
                              font-scale="1.6"
                              style="margin-left: -8px;"
                            />
                          </b-button>
                        </b-input-group-prepend>
                        <vue-numeric
                          v-model="setInputs[data.index].inputs.addQty"
                          separator=","
                          :max="data.amount * 1"
                          class="text-center"
                          style="padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
        position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;"
                        />

                        <!-- here -->
                        <!-- <VueNumberFormat
                          v-model="data.inputs.addQty"
                          @change="(e) => {
                            console.log(e)
                          }"

                        /> -->

                        <!-- <b-form-input
                          v-model="input.amount"
                          type="number"
                          min="0"
                          max="10000"
                          class="border-secondary text-center"
                          style="padding: 0 !important;border: 1px solid #d8d6de !important;"
                          number
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          :readonly="statusPage==='แก้ไข'? true:false"
                        /> -->
                        <b-input-group-append>
                          <b-button
                            variant="outline-dark"
                            class="py-0"
                            size="sm"
                            style="width: 35px;
  border: 1px solid #d8d6de !important;
      color: white;
    background-color: #29C76F;"
                            @click="valueAmountChange(-1, data.index)"
                          >
                            <b-icon
                              icon="plus"
                              font-scale="1.6"
                              style="margin-left: -8px;"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div
                      class="row"
                      style="    margin-top: 8px;"
                    >
                      <div class="col-12">
                        <div class="d-flex justify-content-center">
                          <b-button
                            variant="outline-dark"
                            class="border-primary"
                            size="sm"
                            style="width: 35px;
    padding: 0px;
    height: 25px;margin-right: 3px;"
                            @click="valueAmountChange(-10, data.index)"
                          >10
                          </b-button>
                          <b-button
                            variant="outline-dark"
                            class="border-primary"
                            size="sm"
                            style="width: 35px;
    padding: 0px;
    height: 25px;margin-right: 3px;"
                            @click="valueAmountChange(-50, data.index)"
                          >50
                          </b-button>
                          <b-button
                            variant="outline-dark"
                            class="border-primary"
                            size="sm"
                            style="width: 35px;
    padding: 0px;
    height: 25px;"
                            @click="valueAmountChange(-100, data.index)"
                          >100
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="currentPage"
              hide-goto-end-buttons
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="justify-content-end"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="my-modal"
      v-model="IsopenModal"
      hide-footer
      header-bg-variant="primary"
      size="lg"
    >
      <template v-if="modalPage == 0">
        <div>
          <div
            v-for="(data, index) of dataOrder"
            :key="index"
            class="row"
            style="margin-bottom: -30px;"
          >
            <div class="col-12">
              <div
                class="card carditem"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-3 text-center">
                      <b-img
                        thumbnail
                        fluid
                        :src="baseURL + data.items.product.pic"
                        alt="Image 1"
                        style="width:100%;max-width: 80px;"
                        @error="setAltImg"
                      />
                      <p class="mt fSRP">
                        {{ data.items.product.code }}
                      </p>
                    </div>
                    <div class="col-9">
                      <div class="row mrcol">
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            ชื่อสินค้า
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex justify-content-between">
                            <p class="text-truncate mr-1 text-left">
                              {{ data.items.product.name }}
                            </p>
                            <div class="settrash">
                              <b-button
                                variant="danger"
                                @click="onDeleteOrderList(index)"
                              >
                                <feather-icon
                                  class="mgfeather text-center"
                                  icon="TrashIcon"
                                />
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mrcol">
                        <div class="col-4 nonepading ">
                          <p>
                            คลัง
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <p>{{ data.wareHouseName }}</p>
                        </div>
                      </div>
                      <div class="row mrcol">
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            จำนวน
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex">
                            <div v-if="data.items.promotion.length > 0">
                              <p class="fSRP">
                                {{
                                  data.inputs.addQty | toAmount
                                }}
                                &nbsp;/&nbsp;
                                {{ data.items.priceProduct | toCurrency }}
                                <b-badge
                                  style="margin-left:3px"
                                  variant="danger"
                                >
                                  {{
                                    `${data.items.promotion[0].value.$numberDecimal} %`
                                  }}
                                </b-badge>
                                <span
                                  v-if="sizepagee !== 'xs'"
                                  class="ml-1"
                                >บาท</span>
                              </p>
                            </div>
                            <div v-else>
                              <p class="fSRP mr-1">
                                {{
                                  data.inputs.addQty | toAmount
                                }}
                                &nbsp;/&nbsp;
                                {{
                                  data.items.priceProduct | toCurrency
                                }}&nbsp;บาท
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-show="inputDiscount.checkbox"
                        class="row mrcol"
                      >
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            ส่วนลด
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex align-items-center">
                            <VueNumberFormat
                              v-model="data.inputs.Additionaldiscounts"
                              class="text-right inputFormat"
                              style=""
                              @input="setDiscountAdd(index)"
                            />
                            <b-badge
                              style="margin-left:3px;height: 20px;"
                              variant="danger"
                            >
                              {{ `${inputDiscount.value} %` }}
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="row mrcol">
                        <div class="col-4 nonepading">
                          <p class="text-truncate">
                            ราคารวม
                          </p>
                        </div>
                        <div class="col-8 nonepading">
                          <div class="d-flex">
                            <p class="fSRP mr-1">
                              {{
                                (data.inputs.addQty * data.items.priceProduct)
                                  | toCurrency
                              }}
                            </p>
                            <p>
                              บาท
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <b-form-group>
                        <label>{{ `เลือกลัง ( ` }}{{ data.inputs.addQty | toAmount }}{{ ` / ` }}<span :class="parseFloat(data.inputs.addQty) !== parseFloat(data.qtyTotalbox)?'text-danger':''">{{ data.qtyTotalbox | toAmount }}</span>{{ ` )` }}</label>
                        <multiselect
                          v-model="data.valueOptionBox"
                          tag-placeholder="Add this as new tag"
                          placeholder="เลือกลัง"
                          label="name"
                          track-by="code"
                          :options="data.dataOptionBox"
                          :multiple="true"
                          @input="setDataBox(index)"
                          @tag="addTag"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <div
                    v-show="renderComponent"
                    class="row"
                  >
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style="width:auto;min-width: 150px;"
                              >
                                ลัง/ล็อด
                              </th>
                              <th
                                scope="col"
                                class="text-right"
                                style="min-width:110px"
                              >
                                คงเหลือ
                              </th>
                              <th
                                scope="col"
                                class="text-right"
                                style="min-width:100px"
                              >
                                จำนวน
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(box, j) of data.valueOptionBox"
                              :key="j"
                            >
                              <td>
                                <p>{{ box.nameText }}<span class="text-primary">{{ ` ( ${box.lot} )` }}</span></p>
                              </td>
                              <td class="text-right">
                                {{ box.amount | toAmount }}
                              </td>
                              <td class="text-right">
                                <div class="d-flex justify-content-end">
                                  <vue-numeric
                                    v-model="box.qty"
                                    separator=","
                                    :max="box.amount * 1"
                                    class="form-control text-center"
                                    style="max-width:70px"
                                    @input="sumTotalbox(index)"
                                  />
                                  <!-- <b-form-input
                                    v-model="box.qty"
                                    class="text-right"
                                    style="max-width:70px"
                                    type="number"
                                    min="0"
                                    @input="sumTotalbox(index)"
                                  /> -->
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card is_sticky"
            :style="
              typeCustomerOrder === '' ? 'height:195px;' : 'height:230px;'
            "
          >
            <div class="card-body">
              <div class="row justify-content-end">
                <div
                  class="col-9 col-sm-6 col-lg-4 text-right"
                  :style="
                    NameCustomerOrder == '' ? '    margin-bottom: 27px;' : ''
                  "
                >
                  <p>
                    ราคารวม &nbsp;{{ sumtotalOrder | toCurrency }} &nbsp;บาท
                  </p>
                  <div
                    v-if="NameCustomerOrder != ''"
                    class="d-flex justify-content-end"
                    style="    margin-top: -10px;"
                  >
                    <feather-icon
                      class="text-center css-1sns0wc mr-1"
                      icon="RotateCwIcon"
                      style="margin-buto;    margin-top: 4px; cursor: pointer;"
                      @click="resetNameCus"
                    />
                    <p class="text-truncate">
                      ลูกค้า &nbsp;{{ NameCustomerOrder }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-content-end align-items-center mb-1"
                    style="    margin-top: -10px;"
                  >
                    <span class="mr-1">ช่องทาง</span>
                    <b-form-select
                      v-model="channel"
                      style="width:120px"
                      :options="Datachannel"
                    />
                  </div>
                  <div
                    v-if="typeCustomerOrder !== ''"
                    class="d-flex justify-content-end align-items-center mb-1"
                    style="    margin-top: -10px;"
                  >
                    <span class="mr-1">ส่วนลด</span>
                    <b-form-checkbox
                      v-model="inputDiscount.checkbox"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="mb-1 d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="mr-1 d-flex"
                  :disabled="isConfirm && nameButtonOrder === 'ยืนยัน'"
                  @click="confirmOrder()"
                >
                  <b-spinner
                    v-if="isConfirm"
                    small
                    style="margin-right:5px"
                  />
                  {{ nameButtonOrder }}
                </b-button>
                <b-button
                  variant="danger"
                  style="width:95px"
                  @click="closeModal()"
                >
                  ย้อนกลับ
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="modalPage == 1">
        <div class="mt-1">
          <addCustomer
            :enter="enter"
            :status="'U'"
            @setPageModal="setPageModal"
            @loadCustomer="loadCustomer"
          />
        </div>
      </template>
      <template v-if="modalPage == 2">
        <div class="row">
          <div class="col-12">
            <div
              class="custom-search d-flex align-items-center justify-content-between mb-1 mt-2"
            >
              <b-form-group style="    margin-bottom: 0rem;">
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchCustomer"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block mr-1"
                  />
                </div>
              </b-form-group>
              <b-form-group
                class="DisplayHide"
                style="margin-bottom: 0rem;"
              >
                <div class="d-flex align-items-center">
                  <label class="mr-1">ประเภท</label>
                  <b-form-select
                    v-model="input.typeCustomer"
                    :options="typeCustomer"
                    class="d-inline-block mr-1"
                  />
                </div>
              </b-form-group>
            </div>
            <!-- <div class="mt-1 mb-1">
              <b-button
                variant="primary"
                style="height: 38px;"
                @click="setPageModal(1)"
              >
                เพิ่ม
              </b-button>
            </div> -->
            <b-table
              striped
              hover
              responsive
              class="type-relative"
              :per-page="inputsnodeCustomer.perPage"
              :current-page="inputsnodeCustomer.currentPage"
              :items="listDataCustomer"
              :fields="fieldsCustomerTable"
              :filter="searchCustomer"
              @filtered="onFiltered"
              @row-clicked="setModal"
            >
              <template #cell(name)="data">
                {{ data.item.firstName }} {{ data.item.lastName }}
              </template>
              <template #cell(type)="data">
                {{ data.item.type === "U" ? "ลูกค้าทั่วไป" : "ตัวแทน" }}
              </template>
            </b-table>
            <b-card-body
              class="d-flex justify-content-between flex-wrap pt-0 mt-2 mb-2"
            >
              <b-form-group
                label="Per Page"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="inputsnodeCustomer.perPage"
                  size="sm"
                  inline
                  :options="inputsnodeCustomer.pageOptions"
                />
              </b-form-group>
              <div>
                <b-pagination
                  v-model="inputsnodeCustomer.currentPage"
                  :total-rows="inputsnodeCustomer.totalRows"
                  :per-page="inputsnodeCustomer.perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

import {
  BImg,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSpinbutton,
  BPagination,
  BButton,
  BModal,
  BTable,
  BCardBody,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
  BIcon,
  BIconDash,
  BIconPlus,
  BBadge,
  BFormCheckbox,
  BSpinner
} from 'bootstrap-vue'
import VueNumeric from 'vue-numeric'
import Multiselect from 'vue-multiselect'
import addCustomer from '@/views/components/production/add_customer.vue'
import PlaceHolder from '@/assets/images/production/img_default.png'
import DateTimePicker from '@/views/components/production/dateTimePicker.vue'

export default defineComponent({
  name: 'sales_order',
  components: {
    BImg,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSpinbutton,
    BPagination,
    BButton,
    BModal,
    BTable,
    BCardBody,
    addCustomer,
    VueNumeric,
    ToastificationContent,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BIcon,
    BIconDash,
    BIconPlus,
    BBadge,
    BFormCheckbox,
    DateTimePicker,
    Multiselect,
    BSpinner
  },
  computed: {
    sumtotalOrder () {
      let sum = 0
      this.dataOrder.forEach(data => {
        sum += data.inputs.addQty * data.items.priceProduct
      })
      return sum
    },
    nameButtonOrder () {
      return this.NameCustomerOrder === '' ? 'เลือกลูกค้า' : 'ยืนยัน'
    },
    listCustomer () {
      return this.$store.state.customer.listCustomer
    },
    checkType () {
      return this.$store.state.customer.listCustomer.filter(
        x => x.type === this.input.typeCustomer
      )
    },
    sizepagee () {
      return this.$store.getters['app/currentBreakPoint']
    }
  },
  watch: {
    typeCustomerOrder: {
      handler (val) {
        this.inputDiscount.checkbox = val === 'V'
      }
    },
    // 'inputDiscount.value': {
    //   handler (val) {
    //     this.setdataOrder()
    //   }
    // },
    'inputDiscount.checkbox': {
      handler (val) {
        if (this.typeCustomerOrder !== '') {
          if (val) {
            const discount = this.itemsDiscount.filter(x => x.type === this.typeCustomerOrder)
            if (discount.length > 0) {
              this.inputDiscount.value = discount[0].value
            }
          } else {
            this.inputDiscount.value = 0
          }
        }

        this.dataOrder = this.dataOrder.map(r => Object.assign(r, { 'inputs.pricedisCount': this.inputDiscount.value, 'inputs.Additionaldiscounts': 0 }))
      }
    },
    searchTerm (Val) {
      this.items = this.$store.state.production.dataProductOrder.filter(
        data => data.product.code.includes(Val) || data.product.name.includes(Val)
      )
      this.rows = this.items.length
      this.currentPage = 1
    },
    input: {
      handler (val) {}
    },
    'input.typeCustomer': {
      handler (val) {
        if (val === '') {
          this.listDataCustomer = this.listCustomer
          this.inputsnodeCustomer.totalRows = this.listCustomer.length
          this.inputsnodeCustomer.currentPage = 1
          return
        }
        this.listDataCustomer = this.checkType
        this.inputsnodeCustomer.totalRows = this.checkType.length
        this.inputsnodeCustomer.currentPage = 1
      }
    },
    '$store.state.production.dataProductOrder': {
      handler (val) {
        // v-model="setInputs[data.index].inputs.warehouse"
        //         :options="setInputs[data.index].warehouse"
        this.items = val
        this.rows = this.items.length
        this.setInputs = this.$store.state.production.datas
      }
    },
    setInputs: {
      handler (newVal) {
        this.setSumTotalQtyItem()
      },
      deep: true
    }
  }, // END Watch
  methods: {
    sumTotalbox (index) {
      let sum = 0
      this.dataOrder[index].valueOptionBox.forEach(element => {
        sum += parseFloat(element.qty)
      })
      this.dataOrder[index].qtyTotalbox = sum
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      console.log(tag)
      this.options.push(tag)
      this.valueProduct.push(tag)
    },
    setDiscountAdd (index) {
      const ProductPrice = parseFloat(this.dataOrder[index].items.product.price.$numberDecimal)
      const discount = this.dataOrder[index].inputs.pricedisCount + this.dataOrder[index].inputs.Promotion
      this.dataOrder[index].items.priceProduct = (ProductPrice - ((ProductPrice / 100) * discount)) - parseFloat(this.dataOrder[index].inputs.Additionaldiscounts)
    },
    setAltImg (e) {
      e.target.src = PlaceHolder
    },
    loaderChannel () {
      this.$store.dispatch('setting/getAllChannel').then(result => {
        if (result.data.items.length > 0) {
          this.channel = result.data.items[0]._id
          result.data.items.forEach(item => {
            this.Datachannel.push({ text: item.name, value: item._id })
          })
          // this.$refs.channelRules.reset()
        }
      })
    },
    async loader () {
      await this.loadWarehouse()
      await this.loadOrder()
      await this.loadDiscount()
      this.loaderChannel()
    },
    async loadDiscount () {
      this.$store.dispatch('setting/getAllDiscount').then(result => {
        this.itemsDiscount = []
        result.data.items.forEach(data => {
          this.itemsDiscount.push({
            _id: data._id,
            name: data.name,
            value: parseFloat(data.value.$numberDecimal),
            type: data.type,
            status: true
          })
        })
      })
    },
    async loadOrder () {
      console.log('111', this.datasWareHous)
      await this.$store
        .dispatch('production/getProductOrderAll', this.datasWareHous)
        .then(result => {})
    },
    async loadWarehouse () {
      await this.$store
        .dispatch('warehouse/getWareHouse', this.input)
        .then(result => {
          this.datasWareHous = []
          result.data.items.forEach(data => {
            this.datasWareHous.push({ value: data._id, text: data.name })
          })
        })
    },
    onClickAddAmount (val, index) {
      this.setInputs[index].inputs.addQty += val
    },
    onConfirmOrder () {
      this.isConfirm = true
      const checkSumbox = this.dataOrder.filter(x => parseFloat(x.qtyTotalbox) !== parseFloat(x.inputs.addQty))
      if (checkSumbox.length > 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'โปรดตรวจสอบจำนวนให้ถูกต้อง'
          }
        })
        this.isConfirm = false
        return
      }
      const setDataApi = {
        listProduct: [],
        customerId: this.IDCustomerOrder,
        employerId: this.userData.id,
        paymentId: this.channel,
        DateOrder: this.DateOrder,
        listBox: []
      }
      this.dataOrder.forEach(data => {
        setDataApi.listProduct.push({
          id: data.items.product._id,
          amount: data.inputs.addQty,
          price: parseFloat(data.items.product.price.$numberDecimal),
          warehouseId: data.inputs.warehouse,
          type: '',
          // eslint-disable-next-line radix
          promotion: data.inputs.Promotion,
          discount: data.inputs.pricedisCount,
          specialDiscount: data.inputs.Additionaldiscounts
        })
        data.valueOptionBox.forEach(box => {
          setDataApi.listBox.push({
            id: box.code,
            amount: box.qty
          })
        })
      })

      this.$store.dispatch('order/addOrder', setDataApi).then(result => {
        if (result.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: result.data.message
            }
          })
          this.$router.push({ path: `/orderpayment/${result.data.id}/add` })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: result.data.message
            }
          })
          this.isConfirm = false
        }
      })
    },
    warehouseChange (index) {
      this.$store
        .dispatch('production/getStockWhs', {
          index,
          warehouseId: this.setInputs[this.items[index].index].inputs.warehouse,
          productId: this.items[index].product._id
        })
        .then(res => {
          this.setInputs[this.items[index].index].inputs.addQty = 0
        })
    },
    valueAmountChange (val, index) {
      if (this.setInputs[index].inputs.addQty >= 0) {
        this.setInputs[index].inputs.addQty -= val
        if (this.setInputs[index].inputs.addQty <= 0) { this.setInputs[index].inputs.addQty = 0 }
        if (this.setInputs[index].inputs.addQty >= this.items[index].amount * 1) { this.setInputs[index].inputs.addQty = this.items[index].amount * 1 }
      }
    },
    loadCustomer () {
      this.$store
        .dispatch('customer/getCustomer')
        .then(response => {
          this.listDataCustomer = response.data.items
          this.inputsnodeCustomer.totalRows = response.data.items.length
        })
        .catch(error => {
          console.log(error)
        })
    },
    openModalCheck () {
      let textalert = ''
      const dataMorethen1 = this.setInputs.filter(x => x.inputs.addQty > 0)
      if (dataMorethen1.length === 0) textalert = 'โปรดทำการเลือกสินค้า'

      if (textalert !== '') {
        this.$swal.fire({
          icon: 'info',
          text: textalert
        })
        return
      }
      this.IsopenModal = true
    },
    async setdataOrder () {
      this.dataOrder = []

      const dtStore = await this.$store.dispatch('order/getboxes', this.setInputs)
      this.setInputs.forEach((data, index) => {
        if (data.inputs.addQty > 0) {
          const item = this.$store.state.production.dataProductOrder.filter(
            x => x.index === index
          )
          if (item.length > 0) {
            const itemOrder = item[0]
            const Whs = data.warehouse.filter(
              x => x.value === data.inputs.warehouse
            )
            const priceProduct = parseFloat(itemOrder.product.price.$numberDecimal)
            // eslint-disable-next-line radix
            let DiscountANDPromotion = parseInt(this.inputDiscount.value)
            if (itemOrder.promotion.length > 0) {
              // eslint-disable-next-line radix
              DiscountANDPromotion += parseInt(itemOrder.promotion[0].value.$numberDecimal)
            }
            itemOrder.priceProduct = priceProduct - ((priceProduct / 100) * DiscountANDPromotion)
            const producbox = dtStore.data.items.filter(x => x.box.productId === data.inputs.product)
            const setbox = producbox.map(x => ({ code: x.box._id, name: `${x.box.no}/${x.code} ( ${this.toAmountFormat(x.box.amount)} ชิ้น )`, nameText: x.box.no, qty: 0, amount: x.box.amount, lot: x.code }))
            this.dataOrder.push({
              items: item[0],
              inputs: {
                ...data.inputs,
                ...{ pricedisCount: this.inputDiscount.value },
                Additionaldiscounts: 0,
                // eslint-disable-next-line radix
                Promotion: itemOrder.promotion.length > 0 ? parseInt(itemOrder.promotion[0].value.$numberDecimal) : 0
              },
              qtyTotalbox: 0,
              valueOptionBox: [],
              dataOptionBox: setbox,
              wareHouseName: Whs[0].text
            })
          }
        }
      })
    },
    toAmountFormat (value) {
      let dataVal = value
      if (typeof dataVal !== 'number') {
        dataVal = parseFloat(dataVal)
      }
      const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
      return formatter.format(dataVal)
    },
    async setDataBox (index) {
      let addQty = parseFloat(this.dataOrder[index].inputs.addQty)
      this.dataOrder[index].valueOptionBox = this.dataOrder[index].valueOptionBox.map(r => {
        if (addQty <= 0) {
          return Object.assign(r, { qty: 0 })
        }
        if (addQty >= r.amount) {
          addQty -= r.amount
          return Object.assign(r, { qty: r.amount })
        }
        const setQty = addQty
        addQty = 0
        return Object.assign(r, { qty: setQty })
      })
      this.sumTotalbox(index)
    },
    onDeleteOrderList (index) {
      this.setInputs[this.dataOrder[index].items.index].inputs.addQty = 0
      this.dataOrder = this.dataOrder.filter(
        x => x.items.product.code !== this.dataOrder[index].items.product.code
      )
      if (this.dataOrder.length === 0) this.closeModal()
    },
    setSumTotalQtyItem () {
      this.sumItemShopping = 0
      this.setInputs.forEach(data => {
        if (data.inputs.addQty > 0) {
          this.sumItemShopping += 1
        }
      })
    },
    setModal (item, index, event) {
      this.NameCustomerOrder = `${item.firstName} ${item.lastName}`
      this.IDCustomerOrder = item._id
      this.typeCustomerOrder = item.type

      this.setPageModal(0)
    },
    setPageModal (index) {
      this.modalPage = index
    },
    onFiltered (filteredItems) {
      this.inputsnodeCustomer.totalRows = filteredItems.length
      this.inputsnodeCustomer.currentPage = 1
    },
    confirmOrder () {
      if (this.NameCustomerOrder === '') this.setPageModal(2)
      else this.onConfirmOrder()
    },
    resetNameCus () {
      this.NameCustomerOrder = ''
      this.IDCustomerOrder = ''
      this.typeCustomerOrder = ''
      this.inputDiscount = {
        checkbox: false,
        value: 0
      }
    },
    closeModal () {
      this.IsopenModal = false
    },
    onAddOrderButton () {
      const item = {
        listProduct: this.dataOrder,
        customerId: this.customerId,
        employerId: this.userData.id
      }
      this.$store
        .dispatch('order/add', item)
        .then(response => {
          if (response.data.success) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ลูกค้า',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ลูกค้า',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message
              }
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ลูกค้า',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: err.message
            }
          })
        })
    },
    onSetDatePicker (data) {
      this.DateOrder = moment(data.val).format('YYYY-MM-DD')
    },
    forceRerender () {
      // Removing my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
    }
  },
  created () {
    this.loader()
    this.loadCustomer()
    this.item = this.datas
  },
  data () {
    return {
      isConfirm: false,
      inputDiscount: {
        checkbox: false,
        value: 0
      },
      renderComponent: true,
      valueProduct: [],
      options: [],
      DateOrder: moment(),
      baseURL: this.$baseURL,
      userData: JSON.parse(localStorage.getItem('userData')),
      input: { typeCustomer: '' },
      setInputs: [],
      items: [],
      datasWareHouse: [],
      enter: 'modal',
      IsopenModal: false,
      searchTerm: '',
      currentPage: 1,
      perPage: 6,
      rows: 0,
      sumItemShopping: 0,
      modalPage: 0,
      searchCustomer: '',
      listDataCustomer: null,
      customerId: '',
      dataOrder: [],
      NameCustomerOrder: '',
      IDCustomerOrder: '',
      typeCustomerOrder: '',
      Datachannel: [],
      channel: '',
      inputsnodeCustomer: {
        perPage: 3,
        pageOptions: [3, 5, 10],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      },

      typeCustomer: [
        { value: '', text: '-ทั้งหมด-' },
        { value: 'V', text: 'ตัวแทน' },
        { value: 'U', text: 'ลูกค้าทั่วไป' }
      ],
      dataCustomer: [],
      fieldsCustomerTable: [
        {
          key: 'code',
          label: 'รหัสลูกค้า',
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: 'width:30%;min-width: 160px;',
          sortable: true
        },
        {
          key: 'name',
          label: 'ชื่อลูกค้า',
          tdClass: 'text-truncate',
          thStyle: 'width:40%;min-width: 200px;'
        },
        {
          key: 'telephoneNumber',
          label: 'เบอร์โทร',
          thStyle: 'width:15%;min-width:125px'
        },
        { key: 'type', label: 'ประเภท', thStyle: 'width:15%;min-width: 150px;' }
      ],
      itemsDiscount: []
    }
  }
})
</script>
<style scoped>
.inputFormat {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
  height: 25px;
  max-width: 70px;
  margin-right: 5px;
}
.empty-cart {
  width: 100px;
  height: 100px;
  color: gray;
  opacity: 0.3;
}
.empty-font {
  font-size: 40px;
  color: gray;
  opacity: 0.3;
}
.carditem {
  box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.16) !important;
  -webkit-box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.16) !important;
  -moz-box-shadow: 0px 0px 40px -14px rgba(0, 0, 0, 0.16) !important;
}
#modal-center___BV_modal_body_ {
  padding: 0px;
}
.modal-open .modal {
  overflow-y: unset !important;
}
.scrollable-card {
  overflow-y: auto !important;
}
.nonepading {
  padding: 0px;
}
.fSRP {
  font-size: auto;
}
.mrcol {
  margin-bottom: -10px;
}
.is_sticky {
  height: 100px;
  bottom: 0;
  position: sticky;
}

@media only screen and (max-width: 600px) {
  .shopping {
    width: 70px;
    height: 70px;
  }
  .mgfeather {
    margin-left: -6px;
  }
  .settrash button {
    width: 10px;
  }
  .settrash {
    margin-top: -12px;
  }
  .DisplayHide {
    display: none;
  }
  .setsizeicon {
    min-width: 20px;
    min-height: 20px;
    margin-right: 4px;
    margin-top: 7px;
  }
  .seticonwithp {
    margin-left: 6px;
  }
}
@media only screen and (min-width: 600px) {
  .shopping {
    width: 90px;
    height: 90px;
    /* margin-left: -18px; */
  }
  .setsizeicon {
    min-width: 25px;
    min-height: 25px;
    margin-right: 4px;
    margin-top: 7px;
    /* margin-left: -10px; */
  }
  .seticonwithp {
    margin-left: 15px;
  }
  .settrash {
    margin-right: 20px;
  }
}
</style>
<style>
.spin-cus button:last-child {
  background-color: #29c76f !important;
}
.spin-cus button:first-child {
  background-color: #ea5354 !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
